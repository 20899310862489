import React, { useState, useRef } from "react";
import { useStaticQuery, graphql } from 'gatsby';

const Formulaire = () => {
  const formRef = useRef(null);
  const scriptUrl =
    "https://script.google.com/macros/s/AKfycbwa7TCAPQGKjEA9o1I4B0zh9bpsr_YcXqgOE-oBqia2Gv4HkuVWhQZHLGS86wDjej4K/exec";
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    fetch(scriptUrl, {
      method: "POST",
      body: new FormData(formRef.current),
    })
      .then((res) => {
        //console.log("SUCCESSFULLY SUBMITTED")
        window.location.assign("/contact-validation/");
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  //const [data, setData] = useState()

  //const SITE_URL = process.env.SITE_URL

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
          url
        }
      }
    }
  `);
  //{data.site.siteMetadata.departement}

  return (
    <div id="devis" className="relative isolate bg-white">
      <div className="mx-auto grid max-w-7xl grid-cols-1">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900">
          Devis personnalisé et conseils
        </h2>
        <form
          className="pt-10 pr-5 pl-5 grid grid-cols-1 gap-y-6"
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <div>
            <label htmlFor="last-name" className="sr-only">
              Nom
            </label>
            <input
              required
              type="text"
              name="Nom"
              id="last-name"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-kdp focus:border-kdp border-gray-300 rounded-md"
              placeholder="Nom"
            />
          </div>
          <div>
            <label htmlFor="first-name" className="sr-only">
              Prénom
            </label>
            <input
              required
              type="text"
              name="Prénom"
              id="first-name"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-kdp focus:border-kdp border-gray-300 rounded-md"
              placeholder="Prénom"
            />
          </div>
          <div>
            <label htmlFor="first-name" className="sr-only">
              Code postal
            </label>
            <input
              required
              type="text"
              name="Code Postal"
              id="cp"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-kdp focus:border-kdp border-gray-300 rounded-md"
              placeholder="Code postal"
            />
          </div>
          <div>
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              required
              id="email"
              name="Email"
              type="email"
              autoComplete="email"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-kdp focus:border-kdp border-gray-300 rounded-md"
              placeholder="Email"
            />
          </div>
          <div>
            <label htmlFor="phone" className="sr-only">
              Téléphone
            </label>
            <input
              required
              type="text"
              name="Téléphone"
              id="phone"
              autoComplete="tel"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-kdp focus:border-kdp border-gray-300 rounded-md"
              placeholder="Téléphone"
            />
          </div>
          <div className="pb-4">
            <label htmlFor="phone" className="sr-only">
              Type de demande
            </label>
            <select
              name="Catégorie"
              id="specialite"
              autoComplete="specialite"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-kdp focus:border-kdp border-gray-300 rounded-md"
              placeholder="Votre demande porte sur"
              required
            >
              <option value="">---</option>
              <option value="Avocat">Avocat</option>
              <option value="Kit fissuration">Kit fissuration</option>
              <option value="Expertise bâtiment">Expertise bâtiment</option>
              <option value="Etude de sol G5">Etude de sol G5</option>
              <option value="Assistance à Maitrise d’ouvrage">
                Assistance à Maitrise d’ouvrage
              </option>
              <option value="Maitrise d’oeuvre">Maitrise d’oeuvre</option>
              <option value="Travaux d’injection résine expansive">
                Travaux d’injection résine expansive
              </option>
              <option value="Travaux de micropieux">
                Travaux de micropieux
              </option>
              <option value="Je ne sais pas">Je ne sais pas</option>
            </select>
          </div>
          <div>
            <label htmlFor="message" className="sr-only">
              Message
            </label>
            <textarea
              required
              id="message"
              name="Message"
              rows={4}
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-kdp focus:border-kdp border border-gray-300 rounded-md"
              placeholder="Message"
              defaultValue={""}
            />
          </div>
          <div>
            <label htmlFor="message" className="sr-only">
              Message
            </label>
            <input
              type="checkbox"
              id="cgu"
              name="CGU"
              className="inline mr-3 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-kdp focus:border-kdp border-gray-300 rounded-md"
              required
            />{" "}
            J'accepte les{" "}
            <a
              href="/pages-juridiques/conditions-utilisation/"
              className="text-kdp"
              target="_blank"
              rel="noreferrer"
            >
              Conditions Générales d'Utilisation
            </a>
          </div>
          <div className="hidden">
            <label htmlFor="tellepro" className="sr-only">
              Téllépro
            </label>
            <input
              type="text"
              name="tellepro"
              id="tellepro"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-kdp focus:border-kdp border-gray-300 rounded-md"
              placeholder="Téllépro"
            />
            <input
              type="text"
              name="Source"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-kdp focus:border-kdp border-gray-300 rounded-md"
              placeholder="Source"
              value={data.site.siteMetadata.url}
            />
            <input
              type="text"
              name="Moyen"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-kdp focus:border-kdp border-gray-300 rounded-md"
              placeholder="Moyen"
              value="Formulaire"
            />
          </div>
          <div>
            <button
              type="submit"
              className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium text-white bg-cta hover:bg-cta-over rounded-full pt-3 pb-3 pr-5 pl-5"
            >
              {loading ? "Envoi en cours ..." : "Envoyer"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Formulaire;
